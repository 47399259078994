import { Divider, Layout, Space, Typography } from "antd";
import React, { FC } from "react";

import Container from "@/components/container";
import { HeartFilled } from "@ant-design/icons";
import Image from "next/image";
import dayjs from "dayjs";
import logo from "public/loghi/foxvalley-completo.svg";
import styles from "./Footer.module.scss";

const Footer: FC = () => {
  return (
    <div id="footer" className={styles.Wrapper}>
      <Layout.Footer className={styles.Footer}>
        <Container>
          <a
            target="_blank"
            rel="noreferrer"
            className={styles.Logo}
            href="https://www.foxvalley.net/"
          >
            <Image src={logo} alt="FoxValley Internet" />
          </a>

          <Typography.Text className={styles.Copy}>
            <b>Fox Valley Internet</b> <br />
            2585 Millennium Dr, Ste G - Elgin, IL 60124 <br />
            &copy; Copyright {dayjs(new Date()).format("YYYY")} Fox Valley
          </Typography.Text>

          <Space
            wrap
            size={4}
            className={styles.Links}
            split={<Divider type="vertical" />}
          >
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.SviluppoSicuro}
              href="https://www.foxvalley.net/acceptable-use-policy/"
            >
              Acceptable use policy
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              className={styles.SviluppoSicuro}
              href="https://www.foxvalley.net/privacy-policy/"
            >
              Privacy policy
            </a>

            <Typography.Text>
              R-innovazione digitale by{" "}
              <a
                title="OpiHUB"
                target="_blank"
                rel="noreferrer"
                className={styles.Hub}
                href="https://www.opiquad.it/opihub"
              >
                OpiHUB <HeartFilled />
              </a>
            </Typography.Text>
          </Space>
        </Container>
      </Layout.Footer>
    </div>
  );
};

export default Footer;
