import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";

import { GetServerSidePropsContext } from "next";
import Router from "next/router";
import { createUploadLink } from "apollo-upload-client";
import { extractFiles } from "extract-files";
import getConfig from "next/config";
import { getCookie } from "cookies-next";
import { isEqual } from "lodash";
import merge from "deepmerge";
import { setContext } from "@apollo/client/link/context";
import { useMemo } from "react";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const APOLLO_STATE_PROPERTY_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const LINK_OPTIONS: HttpOptions | createUploadLink.UploadLinkOptions = {
  uri: serverRuntimeConfig.apiURL || publicRuntimeConfig.apiURL,
  credentials: "include",
};

const createApolloClient = (ctx?: GetServerSidePropsContext | null) => {
  const httpLink = ApolloLink.split(
    (operation) => extractFiles(operation).files.size > 0,
    createUploadLink(LINK_OPTIONS),
    new HttpLink(LINK_OPTIONS)
  );

  const authLink = setContext((_, { headers }) => {
    const token = getCookie("access_token", { req: ctx?.req });
    const language = getCookie("NEXT_LOCALE", { req: ctx?.req });

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "Accept-Language": language, // Da problemi con l'utilizzo della variabile Route, perchè non si può utilizzare in gssp
      },
    };
  });

  const logoutLink = onError((res: ErrorResponse) => {
    const { graphQLErrors } = res;

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.message === "Unauthenticated.") {
          if (ctx?.res) {
            ctx.res.writeHead(301, { location: "/logout" });
            ctx.res.end();
          } else {
            Router.replace("/logout");
          }

          break;
        }
      }
    }
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([authLink, logoutLink, httpLink]),
    cache: new InMemoryCache(),
    credentials: "include",
  });
};

export function initializeApollo(
  initialState = null,
  ctx: GetServerSidePropsContext | null = null
) {
  const client = apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps ? pageProps[APOLLO_STATE_PROPERTY_NAME] : null;
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
