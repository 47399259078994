import { Button, Result } from "antd";
import React, { FC } from "react";

import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

const Ops: FC = () => {
  const { t } = useTranslation("ops");
  return (
    <Result
      status="500"
      title="500"
      subTitle={t("subtitle")}
      extra={
        <Link href="/" passHref>
          <Button type="primary">{t("back-to-login")}</Button>
        </Link>
      }
    />
  );
};

export default Ops;
