
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../styles/globals.scss";
import "antd/dist/reset.css";
import type { AppContext, AppProps } from "next/app";
import { ConfigProvider, Layout, theme } from "antd";
import { GetOauthClientNameQuery, GetOauthClientNameQueryVariables, } from "@/graphql/output/graphql";
import { initializeApollo, useApollo } from "@/http";
import { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import App from "next/app";
import AuthLayout from "@/components/auth-layout";
import Container from "@/components/container";
import { ErrorBoundary } from "@sentry/nextjs";
import Footer from "@/components/footer";
import { GET_OAUTH_CLIENT_NAME } from "@/graphql/login";
import { Locale } from "antd/lib/locale-provider";
import Ops from "@/components/ops";
import dayjs from "dayjs";
import en from "antd/lib/locale/en_US";
import { generate } from "@ant-design/colors";
import it from "dayjs/locale/it";
import itIT from "antd/locale/it_IT";
import relativeTime from "dayjs/plugin/relativeTime";
import { setCookie } from "cookies-next";
import styles from "../styles/App.module.scss";
import { useRouter } from "next/router";
dayjs.extend(relativeTime);
interface MyAppProps extends AppProps {
    clientName: string | null;
}
const color = process.env.NEXT_PUBLIC_BASE_COLOR;
const generated = generate(color as string);
function MyApp({ Component, pageProps, clientName }: MyAppProps) {
    const router = useRouter();
    const [, setLocale] = useState<Locale>(itIT);
    const apolloClient = useApollo(pageProps);
    const { token: { colorBgContainer }, } = theme.useToken();
    const isAuth = router.pathname === "/" ||
        router.pathname.startsWith("/password") ||
        router.pathname.startsWith("/registration");
    useEffect(() => {
        setCookie("NEXT_LOCALE", router.locale, {
            path: "/",
            secure: true,
            expires: dayjs().add(1, "year").toDate(),
        });
        setLocale(router.locale === "it" ? itIT : en);
        dayjs.locale(router.locale === "it" ? it : "en");
    }, [router.locale]);
    return (<ConfigProvider locale={itIT} prefixCls="opi" iconPrefixCls="opiicon" theme={{
            token: {
                colorPrimary: color,
                colorLink: generated[6],
                colorLinkHover: generated[4],
                colorLinkActive: generated[4],
                fontFamily: "Muli",
            },
        }}>
      <ApolloProvider client={apolloClient}>
        <ErrorBoundary fallback={() => <Ops />}>
          {router.pathname === "/logout" ? (<Component {...pageProps}/>) : isAuth ? (<AuthLayout client={clientName}>
              <Component {...pageProps}/>
            </AuthLayout>) : (<Layout>
              <Layout.Content className={styles.Layout}>
                <Container style={{ backgroundColor: colorBgContainer }}>
                  <Component {...pageProps}/>
                </Container>
              </Layout.Content>

              <Footer />
            </Layout>)}
        </ErrorBoundary>
      </ApolloProvider>
    </ConfigProvider>);
}
MyApp.getInitialProps = async (ctx: AppContext) => {
    const pageProps = App.getInitialProps(ctx);
    const { query } = ctx.router;
    let clientName = null;
    if ("client" in query && typeof query.client === "string") {
        const apolloClient = initializeApollo(null, null);
        const res = await apolloClient.query<GetOauthClientNameQuery, GetOauthClientNameQueryVariables>({
            query: GET_OAUTH_CLIENT_NAME,
            variables: {
                client: query.client,
            },
        });
        clientName = res.data.getOauthClientName;
    }
    return {
        pageProps,
        clientName,
    };
};
const __Next_Translate__Page__18fe780e755__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18fe780e755__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  