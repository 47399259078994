import { gql } from "@apollo/client";

export const GET_OAUTH_CLIENT_NAME = gql`
  query GetOauthClientName($client: String!) {
    getOauthClientName(client: $client)
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $client: String!) {
    login(email: $email, password: $password, client: $client) {
      ... on LoginSuccess {
        tokens {
          access_token
          token_type
          expires_in
        }
        redirect
      }

      ... on TwoFactorsAuth {
        qr_code
        token
      }
    }
  }
`;

export const LOGIN_TWO_FACTORS = gql`
  mutation LoginTwoFactors($token: String!, $code: String!, $client: String!) {
    loginTwoFactors(code: $code, token: $token, client: $client) {
      tokens {
        token_type
        access_token
        expires_in
      }
      redirect
    }
  }
`;

export const LOGIN_FROM_COOKIE = gql`
  mutation LoginFromCookie($client: String!) {
    loginFromCookie(client: $client) {
      tokens {
        token_type
        access_token
        expires_in
      }
      redirect
    }
  }
`;
