import React, { FC, PropsWithChildren } from "react";

import Footer from "../footer";
import Image from "next/image";
import Trans from "next-translate/Trans";
import { Typography } from "antd";
import classNames from "classnames";
import logo from "public/loghi/logo-fox.png";
import marchio from "public/loghi/littlefox.svg";
import styles from "./AuthLayout.module.scss";
import useTranslation from "next-translate/useTranslation";

interface AuthLayoutProps {
  client: string | null;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({
  children,
  client,
}) => {
  const { t } = useTranslation("auth-layout");
  return (
    <>
      <div className={styles.Content}>
        <div className={styles.Visual}>
          <div className={styles.VisualBackgroundImg} />

          <div className={styles.VisualText}>
            <Typography.Title level={1}>{t("welcome")}</Typography.Title>

            <Typography.Title level={4}>
              <Trans
                i18nKey="auth-layout:innovation-technology"
                components={[<br key={"br"} />, <b key={"bold"} />]}
              />
            </Typography.Title>
          </div>

          <a href="#footer" className={styles.Marchio}>
            <Image width="50" alt="Marchio" src={marchio} />
          </a>
        </div>

        <div
          className={classNames(styles.Body, {
            [styles.BodyWithClient]: client,
          })}
        >
          {client && <div className={styles.Client}>{client}</div>}

          <div className={styles.Form}>
            <div className={styles.Logo}>
              <Image alt="FoxValley Internet" src={logo} />
            </div>

            {children}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AuthLayout;
