module.exports = {
  locales: ["en"],
  defaultLocale: "en",
  pages: {
    "*": [
      "error-feedback",
      "auth-layout",
      "link-to-login",
      "ops",
      "403",
      "404",
      "rules",
    ],
    "/": ["login", "two-factors"],
    "/profile": [
      "profile",
      "avatar",
      "file-uploader",
      "form-password-profile",
      "form-profile",
      "password-confirm",
      "password-form",
    ],
    "/password/recovery": ["recovery"],
    "/password/reset": ["reset", "password-form", "password-confirm"],
    "/registration/[token]": [
      "registration",
      "password-form",
      "password-confirm",
    ],
  },
};
